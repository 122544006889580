import Axios from "axios";
import {mainUrl} from "../../common/common";
import React, {forwardRef, useRef} from "react";
import {Button, Card, CardHeader, CardTitle, Col, Row} from "reactstrap";
import ReactToPrint from "react-to-print";
import {Spinner} from "react-bootstrap";

import ReactHTMLTableToExcel from 'react-html-table-to-excel';

function TableData(props) {

    const update = async (e) => {
        e.preventDefault();
        const headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Header': '*',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
        }

        var status = document.getElementsByName('status[]');
        var emp = document.getElementsByName('employee_id[]');
        var date = document.getElementsByName('date[]');
        var remark = document.getElementsByName('remark[]');
        var k = "[";
        for (var i = 0; i < emp.length; i++) {

            var s = status[i];
            var e = emp[i];
            var d = date[i];
            var r = remark[i];
            k = k + '{' + '"status":' + s.value + ', "date": "' + d.value + '", "remark": "' + r.value + '", "employee_id":' + e.value + "}";
            if (i < emp.length - 1) {
                k = k + ',';
            }
        }
        k = k + "]";
        console.log(k);
        var obj = JSON.parse(k);
        const {data} = await Axios.post(mainUrl + "resultAnalysisReport", {
            value: obj,
        }, {headers});
        const lists = data;
    }
    const ComponentToPrint = forwardRef((prop, reference) => (
        <div className="card table table-reference" ref={reference}>
            <table id="myTable" className="table ">
                <thead className="text-primary">
                <tr>
                    <td colSpan="10" className="text-primary content-center h3 p-5"
                        style={{textAlign: 'center'}}>
                        Salary Report
                    </td>
                </tr>
                <tr>
                    <td colSpan="10">
                        <table border="0"
                               style={{border: '0px solid !important', color: 'gray', width: "100%"}}>
                            <thead>
                            <tr>
                                <td style={{width: "30%", whiteSpace: "nowrap"}}><span
                                    className="h6 text-black"> Project: </span> {props.headerData.province_name}
                                </td>
                                <td style={{width: "20%", whiteSpace: "nowrap"}}><span
                                    className="h6 text-black"> Start Date: </span> {props.periodDate.startDate}
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "25%", whiteSpace: "nowrap"}}><span
                                    className="h6 text-black"> Province: </span> EMP-{props.headerData.id}
                                </td>

                                <td><span className="h6 text-black"> End Date:</span> {props.periodDate.endDate}</td>
                            </tr>
                            <tr>
                                <td style={{width: "25%", whiteSpace: "nowrap"}}><span
                                    className="h6 text-black"> District: </span> {props.headerData.position_name}
                                </td>
                            </tr>
                            </thead>
                        </table>

                    </td>
                </tr>
                <tr>
                    <th>No</th>
                    <th>Date</th>
                    <th>Employee Id : [Name]</th>
                    <th>Salary</th>
                    <th>Accept [Days]</th>
                    <th>Pending [Days]</th>
                    <th>Reject [Days]</th>
                    <th>Tax</th>
                    <th>Result Salary</th>
                </tr>
                </thead>
                <tbody>
                {props.list.map((e, index) => (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{e.date}</td>
                        <td>{e.employee_name}</td>
                        <td>{e.salary}</td>
                        <td>{e.accept}</td>
                        <td>{e.pending}</td>
                        <td>{e.reject}</td>
                        <td>0</td>
                        <td>{e.result_salary}</td>

                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    ));
    const ComponentToPrint1 = forwardRef((prop, reference) => (
        <div className="card table table-reference p-4" ref={reference}>

            <form onSubmit={(e) => update(e)}>
                <table id="myTable" className="table  ">
                    <thead className="text-primary">
                    <tr>
                        <td colSpan="10" className="text-primary content-center h3 p-5"
                            style={{textAlign: 'center'}}>
                            Review the Employee Salary Analysis
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="10">
                            <table border="0"
                                   style={{border: '0px solid !important', color: 'gray', width: "100%"}}>
                                <thead>
                                <tr>
                                    <td rowSpan="5" style={{width: "25%", whiteSpace: "nowrap"}}>
                                        <img
                                            src={props.headerData.image} height="125"
                                            width="auto"/>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{width: "25%", whiteSpace: "nowrap"}}><span
                                        className="h6 text-black"> Name: </span>{props.headerData.name}
                                    </td>
                                    <td style={{width: "30%", whiteSpace: "nowrap"}}><span
                                        className="h6 text-black"> Province: </span> {props.headerData.province_name}
                                    </td>
                                    <td style={{width: "20%", whiteSpace: "nowrap"}}><span
                                        className="h6 text-black"> Start Date: </span> {props.periodDate.startDate}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{width: "25%", whiteSpace: "nowrap"}}><span
                                        className="h6 text-black"> Employee Id: </span> EMP-{props.headerData.id}
                                    </td>
                                    <td style={{width: "15%", whiteSpace: "nowrap"}}><span
                                        className="h6 text-black"> District: </span> {props.headerData.branch_name}
                                    </td>
                                    <td><span className="h6 text-black"> End Date:</span> {props.periodDate.endDate}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{width: "25%", whiteSpace: "nowrap"}}><span
                                        className="h6 text-black"> Position: </span> {props.headerData.position_name}
                                    </td>
                                    <td style={{width: "0%", whiteSpace: "nowrap"}}><span
                                        className="h6 text-black"> Salary: </span> {props.headerData.salary}
                                        AFN
                                    </td>
                                    <td style={{width: "0%", whiteSpace: "nowrap"}}><span
                                        className="h6 text-black">Total Workday: </span> {props.periodDate.workDays}
                                    </td>

                                </tr>
                                </thead>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <th>No</th>
                        <th>Date</th>
                        <th>Login Area</th>
                        <th>Logout Area</th>
                        <th>Work Time</th>
                        <th>Present Student</th>
                        <th className="d-print-none">Selfie Image</th>
                        {/*<th className="d-print-none" style={{whiteSpace: "nowrap"}}>Action</th>*/}
                    </tr>
                    </thead>
                    <tbody>
                    {props.list.map((e, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{e.date}</td>
                            <td>{e.in_status == 1 && (<span className="badge badge-success">&#10004;</span>) }
                                {e.in_status == 2 && (<span className="badge badge-success">&#10060;</span>)}
                                {/*<span className="p-3 d-print-none">*/}
                                {/*        <a target="_blank" title="District finder"*/}
                                {/*           href={"https://www.google.com/maps/@"+e.in_address+",118m/data=!3m1!1e3?hl=en_US"}>*/}
                                {/*            <span className="nc-icon nc-zoom-split"/> </a>*/}
                                {/*        </span>*/}
                                {/*    <br/>*/}
                                {/*    {e.in_address}*/}
                            </td>
                            <td>
                                {e.out_status == 1 &&
                                (<span className="badge badge-success">&#10004;</span>) }
                                {e.out_status == 2 &&  (<span className="badge badge-success">&#10060;</span>)}
                                {/*<span className="p-3  d-print-none">*/}
                                {/*    <a target="_blank" title="District finder"*/}
                                {/*       href="https://www.google.com/maps/@34.5553419,69.2075838,118m/data=!3m1!1e3?hl=en_US">*/}
                                {/*        <span className="nc-icon nc-zoom-split"/> </a>*/}
                                {/*    </span>*/}
                                {/*<br/>*/}
                                {/*{e.out_address}*/}
                            </td>
                            <td>{e.timer}</td>
                            <td>{e.total_student}</td>
                            <td className="d-print-none">
                                <a target="_blank" href={`data:image/jpeg;base64,${e.image}`}
                                   title="Open Image in New Tab">
                                    <img src={`data:image/jpeg;base64,${e.image}`} height="200"
                                         width="auto"/>
                                </a>
                            </td>
                            <td className="d-print-none report " style={{whiteSpace: "nowrap!important"}}>
                                <input type="hidden" name="date[]" id="id" defaultValue={e.date}/>
                                <input type="hidden" name="employee_id[]" id="employee_id"
                                       defaultValue={e.employee_id}/>
                                {/*<label>*/}
                                {/*    <input type="checkbox" name="status[]" id="status" defaultChecked={e.status}/>*/}
                                {/*    <span className="label btn btn-round"> Accept</span>*/}
                                {/*</label>*/}
                                <div className="row">
                                    <div className="col-md-6">
                                        <select className="form-control report-select-css" name="status[]" id="status"
                                                defaultValue={e.status}>
                                            <option value="1" className="text-success">Accept</option>
                                            <option value="2" className="text-warning">Pending</option>
                                            <option value="3" className="text-danger">Reject</option>
                                        </select>
                                    </div>
                                    <div className="col-md-6">
                                        {/*<Button title="Detail Record" className="btn-round"*/}
                                        {/*        color="danger" type="Button">*/}
                                        {/*    <span className="nc-icon nc-vector"/>*/}
                                        {/*</Button>*/}
                                    </div>
                                    <div className="col-md-6">
                                        <textarea className="form-control" name="remark[]" id="remark"
                                                  placeholder=" Remark . . ." defaultValue={e.remark}></textarea>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    ))}


                    </tbody>
                </table>
                <div className="row" style={{float: 'right'}}>
                    <Button color='success' style={{width: '200px',backgroundColor:"green"}} className="btn btn-success mr-4 ml-4"
                            type="submit">Submit</Button>
                </div>

            </form>
        </div>
    ));


    let componentRef = useRef();
    //

    // let rowNumber = 1;
    return (
        <>

            <Row>
                <Col md="12">
                    <Card className="card-user">
                        <CardHeader>
                            <CardTitle className="mr-4 ml-4">
                                <div className="row">
                                    <div className="ml-right">
                                        <h2> Result Report </h2>
                                    </div>
                                    <div className="ml-end">
                                        <ReactToPrint
                                            trigger={() => <button title="Print Report"
                                                                   className=" btn btn-dark">Print</button>}
                                            content={() => componentRef.current}
                                        />

                                        <ReactHTMLTableToExcel
                                            id="exportButton"
                                            className="btn btn-success exportButton"
                                            table="myTable"
                                            filename="report"
                                            sheet="sheet1"
                                            buttonText="Export to Excel"
                                        />
                                    </div>
                                </div>
                            </CardTitle>
                        </CardHeader>

                        {props.isLoading == 0 ? (
                            <div className="text-center">
                                <Spinner animation="border" variant="info"/>
                            </div>
                        ) : ""}

                        {(props.isLoading == 1 && props.reportType == 1) ?
                            <ComponentToPrint1 ref={componentRef}/> : ""
                        }


                        {(props.isLoading == 1 && props.reportType == 2) ?
                            <ComponentToPrint ref={componentRef}/> : ""
                        }

                    </Card>
                </Col>
            </Row>
        </>
    );
}


export default TableData;
