// @flow
import * as React from 'react';
import {useEffect, useState} from "react";
import Axios from "axios";
import {mainUrl} from "../../common/common";
import {Button, Col, Form, FormGroup, Modal, Row, Table, Spinner, Dropdown} from "react-bootstrap";
import {CardBody, CardHeader, CardTitle, Input} from "reactstrap";
import toast, {Toaster} from "react-hot-toast";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

export default function Position(headers) {
    const [showModal, setShowModal] = React.useState(false);
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [recordId, setRecordId] = React.useState(0);
    const [list, setList] = React.useState([]);

    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        fetchList();
    }, []);

    const fetchList = async () => {
        const {data} = await Axios.get(mainUrl + "getPosition", headers);
        const lists = data;
        setList(lists);
        setIsLoading(true);
    };
    const addPosition = async (e) => {
        e.preventDefault();
        await Axios.post(mainUrl + "addPosition", {
            id: e.target.id.value,
            name: e.target.name.value
        }, headers).then(res => {
            if (e.target.id.value == 0) {
                notifySuccess(' Added Successfully.');
            } else {
                notifySuccess(' Updated Successfully.');
            }
            setList(res.data);
            setShowModal(false);
        }).catch(function (error) {
            notifyError(error.response.data.message);
            setShowModal(false);
        });
    };
    const onDeleteRecord = async (e) => {
        e.preventDefault();
        await Axios.post(mainUrl + "deletePosition", {
            id: recordId
        }, headers).then(res => {
            notifySuccess("Successfully deleted");
            const lists = res.data;
            setList(lists);
        }).catch((error) => {
            notifyError(error.response.data.message);

        });
        setRecordId(0);
        setShowDeleteModal(false);
    }

    const notifyError = (message) => toast.error(message, {
        duration: 6000,
    });
    const notifySuccess = (message) => toast.success(message, {
        duration: 6000,
    });

    return (
        <>
            <div className="content">
                {isLoading === false ? (
                    <div className="text-center">
                        <Spinner animation="border" variant="info"/>
                    </div>
                ) : (<div>
                        <AddItem/>
                        <TableData/>
                        <DeleteItem/>
                    </div>
                )}
            </div>
        </>
    );

    function DeleteItem() {
        return (
            <>
                <Modal show={showDeleteModal} backdrop="static" size="lg" onHide={() => setShowDeleteModal(false)}>
                    <form onSubmit={(e) => onDeleteRecord(e)}>
                        <Modal.Header>
                            <div className="modal-profile">
                                <h2>Confirmation</h2>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="pr-3">
                                <Col md="12">
                                    Are you sure you want to delete?
                                </Col>
                            </Row>
                        </Modal.Body>
                        <div className="modal-footer">
                            <Button title="Close"
                                    className="btn-simple"
                                    type="button"
                                    variant="link"
                                    onClick={() => setShowDeleteModal(false)}>
                                Close
                            </Button>
                            <Button title="Yes, I am sure" className="btn-round" color="danger" type="submit">
                                Yes
                            </Button>
                        </div>
                    </form>
                </Modal>
            </>
        );
    }

    function AddItem() {
        return (
            <>
                <Modal show={showModal} backdrop="static" size="xl" onHide={() => setShowModal(false)}>
                    <Form onSubmit={(e) => addPosition(e)}>
                        <Modal.Header className="justify-content-center">
                            <div className="modal-profile">
                                New Position
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="pr-3">
                                <Input hidden={true} required name="id" id="id" value="0" type="hidden"/>

                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Position name <span className="text text-danger">*</span></label>
                                        <Input required name="Position Name" title="Position Name" id="name"
                                               placeholder="name" type="text"/>
                                    </FormGroup>
                                </Col>

                            </Row>

                        </Modal.Body>
                        <div className="modal-footer">
                            <Button title="Close"
                                    className="btn-simple"
                                    type="button"
                                    variant="link"
                                    onClick={() => setShowModal(false)}>
                                Close
                            </Button>
                            <Button title="Insert & update  Record" className="btn-round" color="danger" type="submit">
                                Add
                            </Button>
                        </div>
                    </Form>
                </Modal>
            </>
        );
    }

    function TableData() {
        let no = 1;
        return (
            <>
                <Row>
                    <Col md="12">
                        <div className="card-user">
                            <CardHeader>
                                <CardTitle className="mr-4 ml-4">
                                    <div className="row">
                                        <div className="ml-right">
                                            <h2> Position List </h2>
                                        </div>
                                        <div className="ml-end">
                                            <ReactHTMLTableToExcel
                                                id="exportButton"
                                                className="btn btn-success exportButton"
                                                table="myTable4"
                                                filename="report"
                                                sheet="sheet1"
                                                buttonText="Export to Excel"
                                            />
                                            <Button title="Add New Record" className="btn-success" variant="primary"
                                                    onClick={() => {
                                                        setRecordId(0);
                                                        setShowModal(true)
                                                    }}>
                                                <span className="nc-icon nc-simple-add"/>
                                            </Button>
                                        </div>
                                    </div>
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Table  id="myTable4">
                                    <thead className="text-primary">
                                    <tr>
                                        <th>No</th>
                                        <th style={{width: '100%'}}>Position Name</th>
                                        <th className="text-right">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {list.map((e) => (
                                        <tr key={e.id} title={'Created By : '+e.created_by_name + '\n'+'Created At : '+e.created_at + '\n'+'Updated By : '+e.updated_by_name + '\n'+'Updated At : '+e.updated_at }>
                                            <td>{no++}</td>
                                            <td  style={{width: '100%'}}>{e.name}</td>

                                            <td style={{whiteSpace: "nowrap"}} className="text-right">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="outline-info" id="dropdown-basic">
                                                        <span className="nc-icon nc-bullet-list-67"/>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Button title="Update & Detail View Record"
                                                                onClick={async () => {
                                                                    await setShowModal(true);
                                                                    document.getElementById('name').value = e.name;
                                                                    document.getElementById('id').value = e.id;
                                                                }}  className="btn-round dropdownlist" variant="light" type="Button" >
                                                            <span className="nc-icon nc-paper text-info"/>&nbsp;&nbsp;
                                                            Update & Detail View Record
                                                        </Button>
                                                        <br/>
                                                        <Button title="Delete Record" onClick={() => {
                                                            setRecordId(e.id);
                                                            setShowDeleteModal(true);
                                                        }}  className="btn-round dropdownlist" variant="light" type="Button"
                                                        >
                                                            <span className="nc-icon nc-simple-delete text-info"/>&nbsp;&nbsp;
                                                            Delete Record
                                                        </Button>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </div>
                    </Col>
                </Row>
            </>
        );
    }
};
