
// export const mainUrl ="http://localhost:8000/api/";
// export const webUrl ="http://localhost:8000/";
export const mainUrl ="https://apicbe.tasallut.com/api/";
export const webUrl ="https://apicbe.tasallut.com/";

// export const mainUrl ="http://192.168.1.108:8000/api/";
// export const webUrl ="http://192.168.1.108:8000/";

// export const mainUrl ="http://172.28.112.1:8000/api/";
// export const webUrl ="http://172.28.112.1:8000/";

export let header = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer '+sessionStorage.getItem('token')
}

export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export const dataURLtoFile= (dataurl, filename)=>{
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
}
