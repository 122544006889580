import "./../../assets/css/bootstrap.min.css"
import React, {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {Card, CardHeader, CardTitle} from "reactstrap";
import Axios from "axios";
import {mainUrl} from "../../common/common";
import toast from 'react-hot-toast';
import AddProfile from "./AddProfile";
import ListProfile from "./ListProfile";
import SearchProfile from "./SearchProfile";

function Profile() {


    const [subAdminStatus, setSubAdminStatus] = useState(1);
    const [provinceList, setProvinceList] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [branchList, setBranchList] = useState([]);
    const [positionList, setPositionList] = useState([]);
    const [classList, setClassList] = useState([]);
    const [list, setList] = React.useState([]);
    const [attachFile, setAttachFiles] = React.useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [localImageProfile, setLocalImageProfile] = useState("");


    const [updateRecordStatus, setUpdateRecordStatus] = React.useState(false);
    const [needObject, setNeedObject] = React.useState({
        'project_id': 0,
        'province_id': 0,
        'branch_id': 0,
        'class_id': 0
    });
    const [searchParameter, setSearchParameter] = React.useState({
        'employee_id': '',
        'email': '',
        'project_id': '',
        'province_id': '',
        'branch_id': '',
        'position_id': ''
    })
    const [searchStatus, setSearchStatus] = React.useState(false);
    const [windowStatus, setWindowStatus] = React.useState(false);


    useEffect(() => {
        loadData();
    }, []);

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }


    function getPositions() {
        return Axios.get(mainUrl + "getPosition", {headers});
    }

    function getProjects() {
        return Axios.get(mainUrl + "getProject", {headers});
    }

    function getBranchs() {
        return Axios.get(mainUrl + "getBranch", {headers});
    }

    function getProvinces() {
        return Axios.get(mainUrl + "getProvince", {headers});
    }

    function getProfiles() {
        const proType = sessionStorage.getItem("profileType");
        return Axios.get(mainUrl + "getProfile?page=1&type=" + proType, {headers});
    }

    const loadData = async () => {
        await Promise.all([getPositions(), getBranchs(), getProvinces(), getProjects()])
            .then(function (results) {
                setPositionList(results[0].data);
                setBranchList(results[1].data);
                setProvinceList(results[2].data);
                setProjectList(results[3].data);
                // setList(results[0].data);
                // setList([{}]);
                // setIsLoading(true);
            }).catch(function (thrown) {
                if (Axios.isCancel(thrown)) {
                    console.log('Request canceled', thrown.message);
                } else {
                    // handle error
                }
            });
        ;
    }
    const profileListData = async () => {
        await Promise.all([getProfiles()])
            .then(function (results) {
                setList(results[0].data);
                setIsLoading(true);
            }).catch(function (thrown) {
                if (Axios.isCancel(thrown)) {
                    console.log('Request canceled', thrown.message);
                } else {
                    // handle error
                }
            });
        ;
    }


    const notifyError = (message) => toast.error(message, {
        duration: 6000,
    });
    const notifySuccess = (message) => toast.success(message, {
        duration: 6000,
    });

    return (
        <>
            <div className="content">
                <div>
                    <Card className="card-user">
                        <CardHeader style={{backgroundColor: "#80808029"}}>
                            <CardTitle className="mr-4 ml-4">
                                <button className="btn btn-primary" onClick={() => {
                                    setIsLoading(false);
                                    sessionStorage.setItem('profileType', 3);
                                    profileListData();
                                    setWindowStatus(true);
                                }}>Admin User
                                </button>
                                <button className="btn btn-primary"
                                        onClick={() => {
                                            setIsLoading(false);
                                            sessionStorage.setItem('profileType', 2);
                                            profileListData();
                                            setWindowStatus(true);
                                        }}
                                >Sub Admin User
                                </button>
                                <button className="btn btn-primary"
                                        onClick={() => {
                                            setIsLoading(false);
                                            sessionStorage.setItem('profileType', 1);
                                            profileListData();
                                            setWindowStatus(true);
                                        }}

                                >App User
                                </button>
                            </CardTitle>
                        </CardHeader>
                    </Card>
                </div>

            {windowStatus == true && (
            <div>
                <Row>
                    <Col md="12">
                        <Card className="card-user">
                            <CardHeader style={{backgroundColor: "#80808029"}}>
                                <CardTitle className="mr-4 ml-4">
                                    <div className="row">
                                        <div className="ml-right">
                                            <h3> User Profile </h3>
                                        </div>
                                    </div>
                                </CardTitle>
                            </CardHeader>
                            <SearchProfile searchStatus={searchStatus} setSearchStatus={setSearchStatus}
                                           searchParameter={searchParameter} setSearchParameter={setSearchParameter}
                                           setProjectList={setProjectList} projectList={projectList}
                                           setLocalImageProfile={setLocalImageProfile}
                                           localImageProfile={localImageProfile} isLoading={isLoading}
                                           setIsLoading={setIsLoading} showModal={showModal}
                                           setShowModal={setShowModal} setList={setList} provinceList={provinceList}
                                           branchList={branchList}
                                           positionList={positionList} headers={headers} notifyError={notifyError}
                                           notifySuccess={notifySuccess}/>
                        </Card>
                    </Col>
                </Row>
                <AddProfile setNeedObject={setNeedObject} needObject={needObject}
                            setUpdateRecordStatus={setUpdateRecordStatus} updateRecordStatus={updateRecordStatus}
                            subAdminStatus={subAdminStatus} setSubAdminStatus={setSubAdminStatus}
                            setAttachFiles={setAttachFiles} attachFile={attachFile} setClassList={setClassList}
                            classList={classList} setProjectList={setProjectList} projectList={projectList}
                            setLocalImageProfile={setLocalImageProfile} localImageProfile={localImageProfile}
                            isLoading={isLoading} setIsLoading={setIsLoading} showModal={showModal}
                            setShowModal={setShowModal} setList={setList} list={list} provinceList={provinceList}
                            branchList={branchList}
                            positionList={positionList} headers={headers} notifyError={notifyError}
                            notifySuccess={notifySuccess}/>
                <ListProfile searchStatus={searchStatus} setSearchStatus={setSearchStatus}
                             positionList={positionList}
                             searchParameter={searchParameter} setSearchParameter={setSearchParameter}
                             setNeedObject={setNeedObject} needObject={needObject}
                             setUpdateRecordStatus={setUpdateRecordStatus} updateRecordStatus={updateRecordStatus}
                             subAdminStatus={subAdminStatus} setSubAdminStatus={setSubAdminStatus}
                             setAttachFiles={setAttachFiles} attachFile={attachFile} setProjectList={setProjectList}
                             projectList={projectList} setLocalImageProfile={setLocalImageProfile}
                             localImageProfile={localImageProfile} isLoading={isLoading} setIsLoading={setIsLoading}
                             showModal={showModal} setShowModal={setShowModal} setList={setList} list={list}
                             headers={headers} notifyError={notifyError}
                             notifySuccess={notifySuccess}/>
            </div>
            )
            }
            </div>
        </>
    );
}


export default Profile;
