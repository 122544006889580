import React, {useEffect} from "react";
import Position from "./Position";
import Branch from "./Branch";
import {CardBody, CardHeader, CardTitle} from "reactstrap";
import {Card, Col, Row, Tab, Tabs} from "react-bootstrap";
import Project from "./Project";

function Settings() {
    const [userType, setUserType] = React.useState('0');
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }
    useEffect(()=>{
        setUserType(sessionStorage.getItem('type'));
    })
    return (
        <>
            <div className="content">

                <Row>
                    <Col md="12">
                        <Card className=" ">
                            <CardHeader style={{backgroundColor: "#80808029"}}>
                                <CardTitle className="mr-4 ml-4">
                                    <div className="row">
                                        <div className="ml-right">
                                            <h3> Setting </h3>
                                        </div>
                                    </div>
                                </CardTitle>
                            </CardHeader>

                            <CardBody className="all-icons mr-4 ml-4">
                                <div id="icons-wrapper">
                                    <Tabs defaultActiveKey="Position" id=" " className="">
                                        {userType == 3 && (
                                        <Tab eventKey="Project" title="Project">
                                            <Project headers={headers}/>
                                        </Tab>
                                        )}
                                        {(userType == 3 || userType == 2) && (
                                        <Tab eventKey="District" title="District">
                                            <Branch headers={headers}/>
                                        </Tab>
                                        )
                                        }
                                        {userType == 3 && (
                                        <Tab eventKey="Position" title="Position">
                                            <Position headers={headers}/>
                                        </Tab>
                                        )}

                                    </Tabs>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );

}

export default Settings;
