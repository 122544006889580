import "./../../assets/css/bootstrap.min.css"
import React from "react";
import {Col, Row} from "react-bootstrap";
import {Card, CardHeader, CardTitle} from "reactstrap";
import Message from "./Message/Message";
import BroadcastMessage from "./BroadcastMessage/BroadcastMessage";

function HeaderMessage() {
    const [messageStatus, setMessageStatus] = React.useState(false);
    const [broadcastMessageStatus, setBroadcastMessageStatus] = React.useState(false);

    const statusMessage = (v) => {
        setMessageStatus(false);
        setBroadcastMessageStatus(false);
        if (v == 'message') {
            setMessageStatus(true);
        } else if (v == 'broadcastMessage') {
            setBroadcastMessageStatus(true);
        }
    }
    return (
        <>
            <div className="content">

                <div>
                    <Row>
                        <Col md="12">
                            <Card className="card-user">
                                <CardHeader style={{backgroundColor: "#80808029"}}>
                                    <CardTitle className="mr-4 ml-4">
                                        <div className="row">
                                            <div className="ml-right">
                                                <button onClick={()=>statusMessage('message')}
                                                        className="btn btn-primary"> Messages
                                                </button>
                                                <button onClick={()=>statusMessage('broadcastMessage')}
                                                        className="btn btn-primary"> Broadcast Message
                                                </button>
                                            </div>
                                        </div>
                                    </CardTitle>
                                </CardHeader>
                            </Card>
                        </Col>
                    </Row>
                    {messageStatus == true &&
                    <Message/>
                    }
                    {broadcastMessageStatus == true &&
                    <BroadcastMessage/>
                    }
                </div>

            </div>
        </>
    );


}


export default HeaderMessage;
